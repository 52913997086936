import React from "react";
//links
export const linkBasePath = "/dashboard";
export const linkLogin = "/";
export const linkPageCMSHome = "/cms/home";
export const linkPageCMSAbout = "/cms/about";
export const linkPageCMSEventsList = "/cms/eventsList";
export const linkPageCMSEventsDetails = "/cms/eventsDetails";
export const linkPageCMSPrivacyPolicy = "/cms/privacyPolicy";
export const linkPageCMSTermsConditions = "/cms/termsConditions";
export const linkPageCreatePartner = "/partner/create";
export const linkPageViewPartner = "/partner/view";
export const linkPageViewUser = "/user/view";
export const linkForgotPassword = "/forgotpassword";
export const linkPageEventTemplates = "/console/eventsTemplates";
export const linkPageCreateNewTemplate =
  "/console/eventsTemplates/createTemplate";
export const linkPageMyEvents = "/console/events";
export const linkPageMarkAttendance = "/console/events/markAttendance";
export const linkPageSlotAvailability = "/console/events/slotAvailability";
export const linkPageEventDetails = "/console/events/eventDetails";
export const linkPageViewPartnerProfile = "/console/partner/viewProfile";
export const linkPageViewUserProfile = "/console/user/viewProfile";
export const linkPageAccountStatement = "/console/events/accountStatement";
export const linkPageReview = "/console/events/review";
export const linkPageZones = "/console/zones";
export const linkPageCreateZone = "/console/zones/createZone";
export const linkPageViewActions = "/console/ViewActions";
export const linkPageViewAllBlogs = "/cms/blogs";
export const linkPageCreateBlog = "/cms/blogs/createBlog";
export const linkPageViewBlog = "/cms/blogs/:id";
export const linkPageViewAllResources = "/cms/resources";
export const linkPageCreateResource = "/cms/resources/createResource";
export const linkPageViewResource = "/cms/resources/:id";
export const linkPageCreateStory = "/cms/stories/createStory";
export const linkPageViewStory = "/cms/stories/:id";
export const linkPageCreateWhatsUp = "/cms/whatsup/createWhatsUpPost";
export const linkPageViewWhatsUp = "/cms/whatsuppost/:id";
export const linkPageViewAllStories = "/cms/stories";
export const linkPageViewAllWhatsUps = "/cms/whatsupposts";
export const linkTestingUrl = "/testing";

//linknames
export const linkNamePageCMSHome = "CMS Home";
export const linkNamePageCMSAbout = "CMS About";
export const linkNamePageCMSEventsList = "CMS Events List";
export const linkNamePageCMSEventsDetails = "CMS Events Details";
export const linkNamePageCMSPrivacyPolicy = "CMS Privacy Policy";
export const linkNamePageCMSTermsConditions = "CMS Terms & Conditions";
export const linkNamePageEventTemplates = "Event Templates";
export const linkNamePageCreatePartner = "Create Partner";
export const linkNamePageViewPartner = "View Partners";
export const linkNamePageViewUser = "View Users";
export const linkNameForgotPassword = "Forgot Password";
export const linkNamePageCreateNewTemplate = "create New Template";
export const linkNamePageMyEvents = "Events";
export const linkNamePageMarkAttendance = "Mark Attendance";
export const linkNamePageSlotAvailability = "Slot Availability";
export const linkNamePageEventDetails = "Event Details";
export const linkNamePageViewPartnerProfile = "View Partner Profile";
export const linkNamePageViewUserProfile = "View User Profile";
export const linkNamePageAccountStatement = "Account Statement";
export const linkNamePageReview = "Review";
export const linkNameZones = "Zones";
export const linkNameCreateZone = "Create Zone";
export const linkNameViewActions = "View Actions";
export const linkNameViewAllBlogs = "View All Blogs";
export const linkNameCreateBlog = "Create Blog";
export const linkNameViewBlog = "View Blog";
export const linkNameViewAllResources = "View All Resources";
export const linkNameCreateResource = "Create Resource";
export const linkNameViewResource = "View Resource";
export const linkNameCreateStory = "Create Story";
export const linkNameViewStory = "View Story";
export const linkNameCreateWhatsUp = "Create WhatsUp";
export const linkNameViewWhatsUp = "View WhatsUp";
export const linkNameViewAllStories = "View All Stories";
export const linkNameViewAllWhatsUps = "View All WhatsUps";


//views
const View1 = React.lazy(() => import("./components/views/View1"));
const View2 = React.lazy(() => import("./components/views/View2"));
const PageCMSHome = React.lazy(() => import("./components/views/PageCMSHome"));
const PageCMSAbout = React.lazy(() =>
  import("./components/views/PageCMSAbout")
);
const PageCMSEventsList = React.lazy(() =>
  import("./components/views/PageCMSEventsList")
);
const PageCMSEventsDetails = React.lazy(() =>
  import("./components/views/PageCMSEventsDetails")
);
const PageCMSPrivacyPolicy = React.lazy(() =>
  import("./components/views/PageCMSPrivacyPolicy")
);
const PageCMSTermsConditions = React.lazy(() =>
  import("./components/views/PageCMSTermConditions")
);
const PageCMSEventTemplates = React.lazy(() =>
  import("./components/views/EventTemplates")
);
const PageCreatePartner = React.lazy(() =>
  import("./components/views/PageCreatePartner")
);
const PageViewPartner = React.lazy(() =>
  import("./components/views/PageViewPartner")
);
const PageViewUser = React.lazy(() =>
  import("./components/views/PageViewUsers")
);
const PageViewPartnerProfile = React.lazy(() =>
  import("./components/views/PageViewPartnerProfile")
);
const PageViewUserProfile = React.lazy(() =>
  import("./components/views/PageViewUserProfile")
);
const PageViewAccountStatement = React.lazy(() =>
  import("./components/views/PageAccountStatement")
);
const PageViewReview = React.lazy(() =>
  import("./components/views/PageReview")
);

const PageCreateNewTemplate = React.lazy(() =>
  import("./components/views/PageCreateNewTemplate")
);

const PageMyEvents = React.lazy(() =>
  import("./components/views/PageEventsList")
);

const PageMarkAttendance = React.lazy(() =>
  import("./components/views/MarkAttendance")
);

const PageSlotAvailability = React.lazy(() =>
  import("./components/views/SlotAvailability")
);

const PageEventDetails = React.lazy(() =>
  import("./components/views/PageEventDetails")
);
const Zones = React.lazy(() => import("./components/views/Zones"));
const CreateZone = React.lazy(() => import("./components/views/CreateZone"));
const ViewActions = React.lazy(() =>
  import("./components/views/PageViewActions")
);

const ViewAllBlogs = React.lazy(() =>
  import("./components/views/PageViewAllBlogs")
);

const CreateBlog = React.lazy(() =>
  import("./components/views/PageCreateBlog")
);

const ViewBlog = React.lazy(() =>
  import("./components/views/PageViewBlog")
);

const ViewAllResources = React.lazy(() =>
  import("./components/views/PageViewAllResources")
);

const CreateResource = React.lazy(() =>
  import("./components/views/PageCreateResource")
);

const ViewResource = React.lazy(() =>
  import("./components/views/PageViewResource")
);
const CreateStory = React.lazy(() =>
  import("./components/views/PageCreateStory")
);

const ViewStory = React.lazy(() =>
  import("./components/views/PageViewStory")
);

const CreateWhatsUp = React.lazy(() =>
  import("./components/views/PageCreateWhatsUp")
);

const ViewWhatsUp = React.lazy(() =>
  import("./components/views/PageViewWhatsUp")
);

const ViewAllStories = React.lazy(() =>
  import("./components/views/PageViewAllStories")
);

const ViewAllWhatsUps = React.lazy(() =>
  import("./components/views/PageViewAllWhatsUp")
);

const testing = React.lazy(() => import("./components/views/testing"));

//routes
const routes = [
  { path: "/console", exact: true, name: "Home", component: View1 },
  { path: "/console/view2", name: "View2", component: View2 },
  {
    path: linkPageCMSHome,
    exact: true,
    name: linkNamePageCMSHome,
    component: PageCMSHome,
  },
  {
    path: linkPageCMSAbout,
    exact: true,
    name: linkNamePageCMSAbout,
    component: PageCMSAbout,
  },
  {
    path: linkPageCMSEventsList,
    exact: true,
    name: linkNamePageCMSEventsList,
    component: PageCMSEventsList,
  },
  {
    path: linkPageCMSEventsDetails,
    exact: true,
    name: linkNamePageCMSEventsDetails,
    component: PageCMSEventsDetails,
  },
  {
    path: linkPageCMSPrivacyPolicy,
    exact: true,
    name: linkNamePageCMSPrivacyPolicy,
    component: PageCMSPrivacyPolicy,
  },
  {
    path: linkPageCMSTermsConditions,
    exact: true,
    name: linkNamePageCMSTermsConditions,
    component: PageCMSTermsConditions,
  },
  {
    path: linkPageCreatePartner,
    exact: true,
    name: linkNamePageCreatePartner,
    component: PageCreatePartner,
  },
  {
    path: linkPageViewPartner,
    exact: true,
    name: linkNamePageViewPartner,
    component: PageViewPartner,
  },
  {
    path: linkPageViewUser,
    exact: true,
    name: linkNamePageViewUser,
    component: PageViewUser,
  },
  {
    path: linkPageEventTemplates,
    exact: true,
    name: linkNamePageEventTemplates,
    component: PageCMSEventTemplates,
  },
  {
    path: linkPageViewPartnerProfile,
    exact: true,
    name: linkNamePageViewPartnerProfile,
    component: PageViewPartnerProfile,
  },
  {
    path: linkPageViewUserProfile,
    exact: true,
    name: linkNamePageViewUserProfile,
    component: PageViewUserProfile,
  },
  {
    path: linkPageAccountStatement,
    exact: true,
    name: linkNamePageAccountStatement,
    component: PageViewAccountStatement,
  },
  {
    path: linkPageReview,
    exact: true,
    name: linkNamePageReview,
    component: PageViewReview,
  },
  {
    path: linkPageCreateNewTemplate,
    exact: true,
    name: linkNamePageCreateNewTemplate,
    component: PageCreateNewTemplate,
  },
  {
    path: linkPageMyEvents,
    exact: true,
    name: linkNamePageMyEvents,
    component: PageMyEvents,
  },
  {
    path: linkPageMarkAttendance,
    exact: true,
    name: linkNamePageMarkAttendance,
    component: PageMarkAttendance,
  },
  {
    path: linkPageSlotAvailability,
    exact: true,
    name: linkNamePageSlotAvailability,
    component: PageSlotAvailability,
  },
  {
    path: linkPageEventDetails,
    exact: true,
    name: linkNamePageEventDetails,
    component: PageEventDetails,
  },
  { path: linkPageZones, exact: true, name: linkNameZones, component: Zones },
  {
    path: linkPageCreateZone,
    exact: true,
    name: linkNameCreateZone,
    component: CreateZone,
  },
  {
    path: linkPageViewActions,
    exact: true,
    name: linkNameViewActions,
    component: ViewActions,
  },
  {
    path: linkPageViewAllBlogs,
    exact: true,
    name: linkNameViewAllBlogs,
    component: ViewAllBlogs,
  },
  {
    path: linkPageCreateBlog,
    exact: true,
    name: linkNameCreateBlog,
    component: CreateBlog,
  },
  {
    path: linkPageViewBlog,
    exact: true,
    name: linkNameViewBlog,
    component: ViewBlog,
  },
  {
    path: linkPageViewAllResources,
    exact: true,
    name: linkNameViewAllResources,
    component: ViewAllResources,
  },
  {
    path: linkPageCreateResource,
    exact: true,
    name: linkNameCreateResource,
    component: CreateResource,
  },
  {
    path: linkPageViewResource,
    exact: true,
    name: linkNameViewResource,
    component: ViewResource,
  },
  {
    path: linkPageCreateStory,
    exact: true,
    name: linkNameCreateStory,
    component: CreateStory,
  },
  {
    path: linkPageViewStory,
    exact: true,
    name: linkNameViewStory,
    component: ViewStory,
  },
  {
    path: linkPageCreateWhatsUp,
    exact: true,
    name: linkNameCreateWhatsUp,
    component: CreateWhatsUp,
  },
  {
    path: linkPageViewWhatsUp,
    exact: true,
    name: linkNameViewWhatsUp,
    component: ViewWhatsUp,
  },
  {
    path: linkPageViewAllStories,
    exact: true,
    name: linkNameViewAllStories,
    component: ViewAllStories,
  },
  {
    path: linkPageViewAllWhatsUps,
    exact: true,
    name: linkNameViewAllWhatsUps,
    component: ViewAllWhatsUps,
  },
  {
    path: linkTestingUrl,
    exact: true,
    name: "Testing",
    component: testing,
  },
  
];

export default routes;
