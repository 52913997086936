import { Growl } from "primereact/growl";
import queryString from "query-string";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import KeyboardEventHandler from "react-keyboard-event-handler";
import SimpleReactValidator from "simple-react-validator";
import welcome from "../../../assets/img/login_img.png";
import { linkForgotPassword } from "../../../routes";
import AuthService from "../../../services/AuthService";
import ButtonBox from "../../ButtonBox";
import TextBox from "../../InputText";
import PasswordText from "../../PasswordText";

class PageLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
    this.validate = this.validate.bind(this);
    this.showError = this.showError.bind(this);
    this.clear = this.clear.bind(this);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  showError(msg) {
    this.growl.show({
      severity: "error",
      summary: "Error Message",
      detail: msg,
    });
  }

  clear() {
    this.growl.clear();
  }

  validate(e) {
    this.validator.showMessageFor(e);
  }

  componentDidMount() {
    //alert(this.validator.allValid());
  }

  validateAllThenSubmit(e) {
    //e.preventDefault();
    if (this.validator.allValid()) {
      let parsed = queryString.parse(this.props.location.search);
      const credentials = {
        username: this.state.email,
        password: this.state.password,
      };
      AuthService.login(credentials)
        .then((res) => {
          if (res.status === 200) {
            var userInfo = {
              username: "",
              accessToken: "",
              refreshToken: "",
              expiryTime: "",
              refreshTokenExpiryTime: "",
            };
            userInfo.username = this.state.email;
            userInfo.accessToken = res.data.accessToken;
            userInfo.refreshToken = res.data.refreshToken;
            userInfo.expiryTime = res.data.expiryTime;
            userInfo.refreshTokenExpiryTime = res.data.refreshTokenExpiryTime;
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            if (parsed.rto) {
              this.props.history.push(atob(parsed.rto));
            } else {
              this.props.history.push("/console");
            }
          } else {
            this.setState({ message: res.data.message });
            AuthService.logOut();
          }
        })
        .catch((err) => {
          if (err && err.data && err.data.message) {
            this.showError(err.data.message);
          } else {
            this.showError("Incorrect Username / Password");
          }
          AuthService.logOut();
        });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
      //alert('The form still has errors!');
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title data-react-helmet="true">At Quest Life – Admin Sign In</title>
          <meta name="title" content="Login" data-react-helmet="true"></meta>
        </Helmet>
        <div className="flex flex-col min-h-screen bg-primary-bg">
          <Growl ref={(el) => (this.growl = el)} />

          <div className="w-full hidden sm:block self-start align-top">
            <img
              className="w-full h-sm object-cover"
              src="https://atquest2.b-cdn.net/tree_bg-min%20(1).jpg"
              alt="bg"
            ></img>
          </div>

          <div className="flex flex-col self-center justify-center top w-full sm:w-xl sm:-mt-48 rounded-lg pb-4 bg-primary-bg">
            <div className="self-center w-full pb-4">
              <a href="/" target="_self">
                <div className="flex flex-col border-b-2 shadow-md">
                  <img
                    className="w-40 pt-4 pb-4 h-auto self-center cursor-pointer"
                    src="https://atquest2.b-cdn.net/AtQuestLogoNew.png"
                    alt="at-quest-logo"
                  />
                </div>
              </a>
            </div>

            <div className="flex flex-col self-center w-full pl-6 pr-6 sm:pl-0 sm:pr-0">
              <div className="self-center w-full sm:w-sm pt-4 pb-4">
                <div className="flex flex-col text-primary text-h3 text-center">
                  Admin
                </div>
              </div>

              <div className="self-center w-full sm:w-sm pt-4 pb-4">
                <div className="flex flex-col">
                  <img className="w-full h-auto" src={welcome} alt="welcome" />
                </div>
              </div>

              <div className="self-center w-full sm:w-sm pt-4 pb-4">
                <KeyboardEventHandler
                  handleKeys={["enter"]}
                  onKeyEvent={(key, e) => {
                    console.log(`key press detected ${key}`);
                    this.validateAllThenSubmit();
                  }}
                >
                  <div className="text-center text-h6">
                    <TextBox
                      placeholder="Email"
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      onBlur={(e) => this.validate("Email")}
                      validation={this.validator.message(
                        "Email",
                        this.state.email,
                        "required|email"
                      )}
                    />
                  </div>
                  <div className="text-center text-h6">
                    <PasswordText
                      value={this.state.password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      onBlur={(e) => this.validate("Password")}
                      validation={this.validator.message(
                        "Password",
                        this.state.password,
                        "required"
                      )}
                    />
                  </div>
                </KeyboardEventHandler>
                <div className="text-center text-xs">
                  <div className="pt-6 text-button3">
                    <ButtonBox
                      label="Sign In"
                      className=" text-button3 w-full "
                      onClick={(e) => this.validateAllThenSubmit()}
                      validation={this.validator.allValid()}
                    />
                  </div>
                  <div className="flex flex-row pt-4">
                    <div
                      style={{ flexBasis: 0 }}
                      className="flex-grow cursor-pointer text-center text-h6"
                      onClick={(e) =>
                        this.props.history.push(linkForgotPassword)
                      }
                    >
                      Forgot Password?
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PageLogin;
