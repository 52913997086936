import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import error from "../../assets/img/error.svg";
import iconCopyBlack from "../../assets/img/icon_copy_black.svg";
import iconCopyGray from "../../assets/img/icon_copy_gray.svg";

class TextBox extends Component {
  constructor(props) {
    super(props);
    this.state = { iconCopy: iconCopyBlack };
    this.showCopiedToClipboardText = this.showCopiedToClipboardText.bind(this);
  }

  showCopiedToClipboardText() {
    this.setState({
      ...this.state,
      copiedToClipboardText: "Copied to clipboard",
      iconCopy: iconCopyGray,
    });
    setTimeout(() => {
      this.setState({
        ...this.state,
        copiedToClipboardText: "",
        iconCopy: iconCopyBlack,
      });
    }, 5000);
  }

  render() {
    let className = null;
    if (this.props.className) {
      className = this.props.className;
    }

    if (this.props.textboxtype && this.props.textboxtype == "rounded") {
      return (
        <React.Fragment>
          {this.props.textlabel ? (
            <div className="text-black text-left text-field-title -my-1">
              {this.props.textlabel}
            </div>
          ) : (
            <div></div>
          )}
          <div>
            <div className="flex flex-row align-middle pb-2 pt-2">
              {this.props.addOnLeft ? (
                <div className="p-inputgroup w-full">
                  <div className="p-inputgroup-addon w-full xl:w-1/3 text-justify text-black text-field border-primary border-2 rounded-r-none rounded-lg bg-primary-variant">
                    {this.props.addOnLeft}
                  </div>
                  <InputText
                    placeholder="Username"
                    {...this.props}
                    className={
                      "transition duration-150 ease-out focus:border-primary text-left text-gray-600 text-field " +
                      " w-full border-gray-300 border-2 rounded-lg placeholder-primary " +
                      className
                    }
                    style={{ paddingLeft: "0.5rem" }}
                  />
                </div>
              ) : (
                <React.Fragment>
                  <InputText
                    placeholder="Email"
                    {...this.props}
                    className={
                      "transition duration-150 ease-out focus:border-primary text-left text-gray-600 text-field " +
                      " w-full border-gray-300 border-2 rounded-lg placeholder-primary " +
                      className
                    }
                    style={{ paddingLeft: "0.5rem" }}
                  />
                  {this.props.AllowCopy ? (
                    <div className="flex align-middle -m-20 text-sm text-primary-medium font-medium cursor-pointer">
                      <CopyToClipboard
                        text={this.props.value}
                        onCopy={() => this.showCopiedToClipboardText()}
                      >
                        <div className=" flex w-auto h-auto self-center text-right">
                          <img
                            className="w-5 h-auto self-center mr-2"
                            src={this.state.iconCopy}
                            alt="input-img"
                          />
                          <div
                            className={
                              this.state.copiedToClipboardText
                                ? "text-primary"
                                : ""
                            }
                          >
                            {" "}
                            Copy
                          </div>
                        </div>
                      </CopyToClipboard>
                    </div>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              )}
            </div>
            {this.props.AllowCopy ? (
              <div className="float-right inline-block text-red-txt text-sm ">
                {this.state.copiedToClipboardText}
              </div>
            ) : (
              ""
            )}
            <div className="flex flex-row align-middle mt-1 text-xs h-3 text-left text-red-txt">
              <div className="pr-1">
                {this.props.validation ? (
                  <img className="w-4" src={error} alt="error" />
                ) : (
                  ""
                )}
              </div>
              <div>{this.props.validation}</div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="pb-2 pt-2">
            <InputText
              placeholder="Text"
              {...this.props}
              className={
                "transition duration-150 ease-out focus:border-primary text-left text-gray-600 text-field " +
                " w-full border-gray-300 border-b-2 placeholder-primary bg-transparent " +
                className
              }
            />
            <div className="flex flex-row align-middle mt-1 text-xs h-3 text-left text-red-txt">
              <div className="pr-1">
                {this.props.validation ? (
                  <img className="w-4" src={error} alt="error" />
                ) : (
                  ""
                )}
              </div>
              <div>{this.props.validation}</div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default TextBox;
