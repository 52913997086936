import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import BaseApiService from './BaseApiService';

const DEFAULT_AUTH_PATH = '/auth/api';

class AuthService extends BaseApiService {
  constructor(props) {
    super(props);

    var _this = this;
    this.getAxios().interceptors.request.use(
      function (config) {
        console.log('Making API Request...');
        // Do something before request is sent
        return config;
      },
      function (error) {
        console.log('API Request ERROR...');
        // Do something with request error
        return Promise.reject(error);
      }
    );

    console.log('Creating interceptor...');
    // Add a response interceptor

    this.getAxios().interceptors.response.use(
      (response) => {
        console.log('API Response received...');
        // Do something with response data
        return response;
      },
      (error) => {
        const originalReq = error.config;
        if (typeof window === 'undefined') {
        } else {
          // let url = _this.getDefaultApiUrl();
          let authHeader = _this.getAuthHeader();

          console.log('API Response ERROR...');
          if (
            error.response.status === 401 &&
            error.config &&
            !error.config.___retry
          ) {
            originalReq.___retry = true;
            console.log('AA>> auth error...');
            return new Promise((resolve, reject) => {
              console.log('AA>> trying renew auth...');
              var userInfo = JSON.parse(localStorage.getItem('userInfo'));
              var username = userInfo.username;
              var refreshToken = userInfo.refreshToken;
              let url = this.getDefaultApiUrl();
              var finalUrl = url + DEFAULT_AUTH_PATH + '/token/renew';
              let res = fetch(finalUrl, {
                method: 'post',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  username: username,
                  refreshToken: refreshToken,
                }),
              })
                .then((res) => res.json())
                .then((res) => {
                  console.log(res);
                  var userInfo = JSON.parse(localStorage.getItem('userInfo'));
                  userInfo.accessToken = res.accessToken;
                  if (res.accessToken) {
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                  }
                  var newHeaders = {
                    Authorization: 'Bearer ' + res.accessToken,
                  };
                  //originalReq.headers = newHeaders;
                  originalReq.headers = {
                    ...originalReq.headers,
                    ...newHeaders,
                  };
                  //
                  return _this.getAxios()(originalReq);
                })
                .catch((err) => {
                  console.log(err);
                  return Promise.reject(err);
                });

              resolve(res);
            });
          } else if (
            //failed after refresh...
            error.config &&
            error.config.___retry &&
            error.response.status === 401
          ) {
            ReactDOM.render(
              <h1 className='flex flex-row w-full items-center justify-center bg-red-700 h-16 text-white'>
                PLEASE LOG IN AGAIN...
              </h1>,
              document.getElementById('errorDiv12399')
            );
          }
        }
        return Promise.reject(error);
      }
    );
  }

  login(credentials) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + btoa(credentials.username + ':' + credentials.password),
    };

    /* console.log("CMS...");
    return axios.get(
      "http://test.atq.viamagus.me/cms/api/model/all?limit=10&start=0",
      //"http://test.atq.viamagus.me/auth/api/token/validate",
      {
        headers: {
          "content-type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ1c2VyMUB2aWFtYWd1cy5jb20iLCJzY29wZXMiOlsiUk9MRV9BQ0NFU1NfVE9LRU4iXSwidXNlclR5cGUiOiJURU5BTlRfVVNFUiIsImlzcyI6ImthaWp1IiwiaWF0IjoxNTg3MTk3NDQyLCJhdWQiOiIxIiwiZXhwIjoxNTg3MTk4MzQyfQ.4EcbIEgfen5TLNnWYDCFMTbwymO3PYXvrDc8Rh3wSbbbb8vISi0RiQcpoEg94UDr8XmMCTt-iXVFOPzYveWx8w",
        },
      }
    ); */
    var url = this.getDefaultApiUrl();
    console.log('CMS...END');
    return axios.post(url + DEFAULT_AUTH_PATH + '/token', null, {
      headers: headers,
    });
  }

  signup(credentials) {
    var url = this.getDefaultApiUrl();
    const body = {
      username: credentials.username,
      password: credentials.password,
    };
    return axios.post(url + DEFAULT_AUTH_PATH + '/user/register', body);
  }

  forgotPasswordGenerateOTP(credentials) {
    var url = this.getDefaultApiUrl();

    return axios.post(
      url +
        DEFAULT_AUTH_PATH +
        '/user/reset/password/otp?username=' +
        credentials.username
    );
  }

  forgotPasswordResetPassword(credentials) {
    var url = this.getDefaultApiUrl();

    const body = {
      username: credentials.username,
      password: credentials.password,
      otp: credentials.otp,
    };
    return axios.post(url + DEFAULT_AUTH_PATH + '/user/reset/password', body);
  }

  resendOTP(credentials) {
    var url = this.getDefaultApiUrl();

    return axios.post(
      url +
        DEFAULT_AUTH_PATH +
        '/user/resend/otp?username=' +
        credentials.username
    );
  }

  activateUser(credentials) {
    var url = this.getDefaultApiUrl();

    const body = {
      username: credentials.username,
      otp: credentials.otp,
    };
    return axios.post(url + DEFAULT_AUTH_PATH + '/user/activate', body);
  }

  refresh(credentials) {
    return axios.post('token/renew', credentials);
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem('userInfo'));
  }

  getAuthHeader() {
    return {
      headers: { Authorization: 'Bearer ' + this.getUserInfo().accessToken },
    };
  }

  getAccessToken() {
    return {
      headers: { Authorization: 'Token ' + this.getUserInfo().accessToken },
    };
  }

  getRefreshCredentials() {
    return {
      username: this.getUserInfo().username,
      refreshToken: this.getUserInfo().refreshtoken,
    };
  }

  logOut() {
    localStorage.removeItem('userInfo');
    return;
  }
}

export default new AuthService();
