import { Growl } from "primereact/growl";
import queryString from "query-string";
import React, { Component } from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";
import SimpleReactValidator from "simple-react-validator";
import welcome from "../../../assets/img/login_img.png";
import logo from "../../../assets/img/logo.png";
import bg from "../../../assets/img/tree_bg.jpg";
import { linkLogin } from "../../../routes";
import AuthService from "../../../services/AuthService";
import AuthenticationCode from "../../AuthenticationCode/AuthenticationCode";
import ButtonBox from "../../ButtonBox/ButtonBox";
import TextBox from "../../InputText/InputText";
import PasswordText from "../../PasswordText/PasswordText";

class PageForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showVerificationCode: false,
      successResettingPassword: false,
      verificationcode: null,
      verificationcodeerror: null,
    };
    this.validate = this.validate.bind(this);
    this.showError = this.showError.bind(this);
    this.clear = this.clear.bind(this);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.handleVerificationCodeChange = this.handleVerificationCodeChange.bind(
      this
    );
    this.validateVerificationCodeThenSubmit = this.validateVerificationCodeThenSubmit.bind(
      this
    );
    this.isVerificationCodeValid = this.isVerificationCodeValid.bind(this);
  }

  showError(msg) {
    this.growl.show({
      severity: "error",
      summary: "Error Message",
      detail: msg,
    });
  }

  clear() {
    this.growl.clear();
  }

  validate(e) {
    this.validator.showMessageFor(e);
  }

  componentDidMount() {
    //alert(this.validator.allValid());
  }

  handleVerificationCodeChange(vals) {
    this.setState({ ...this.state, verificationcode: vals });
  }

  isVerificationCodeValid() {
    if (
      this.state.verificationcode &&
      this.state.verificationcode.length == 6
    ) {
      return true;
    }
    return false;
  }

  validateVerificationCodeThenSubmit(e) {
    if (!this.isVerificationCodeValid()) {
      this.setState({
        ...this.state,
        verificationcodeerror: "6 digit verification code required",
      });
      return;
    }
    let credentials = {
      username: this.state.email,
      otp: this.state.verificationcode,
    };
    let parsed = queryString.parse(this.props.location.search);
    AuthService.activateUser(credentials)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          console.log("Activated User > redirecting to user info page..");
          if (parsed.rto) {
            this.props.history.push(atob(parsed.rto));
          } else {
            this.props.history.push("/console");
          }
        } else {
          this.setState({ message: res.data.message });
          AuthService.logOut();
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          this.showError(err.response.data.message);
        } else {
          this.showError("Incorrect Username / Password");
        }
        AuthService.logOut();
      });
  }

  validateThenForgotPasswordOTP(e) {
    //e.preventDefault();
    if (this.validator.allValid()) {
      let parsed = queryString.parse(this.props.location.search);
      const credentials = {
        username: this.state.email,
      };
      AuthService.forgotPasswordGenerateOTP(credentials)
        .then((res) => {
          if (res.status === 200) {
            this.setState({ ...this.state, showVerificationCode: true });
          } else {
            this.setState({ message: res.data.message });
            AuthService.logOut();
          }
        })
        .catch((err) => {
          this.showError("Incorrect Email");
          AuthService.logOut();
        });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
      //alert('The form still has errors!');
    }
  }

  validateThentForgotPasswordResetPassword(e) {
    //e.preventDefault();
    if (this.validator.allValid()) {
      let parsed = queryString.parse(this.props.location.search);
      const credentials = {
        username: this.state.email,
        password: this.state.password,
        otp: this.state.verificationcode,
      };
      AuthService.forgotPasswordResetPassword(credentials)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              ...this.state,
              successResettingPassword: true,
              showVerificationCode: false,
            });
          } else {
            this.setState({ message: res.data.message });
            AuthService.logOut();
          }
        })
        .catch((err) => {
          this.showError(err.response.data.message);
          AuthService.logOut();
        });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
      //alert('The form still has errors!');
    }
  }

  render() {
    return (
      <div className="flex flex-col min-h-screen bg-primary-bg">
        <Growl ref={(el) => (this.growl = el)} />

        <div className="w-full hidden sm:block self-start align-top">
          <img className="w-full h-sm" src={bg} alt="bg"></img>
        </div>

        <div className="flex flex-col self-center justify-center top w-full sm:w-xl sm:-mt-48 rounded-lg pb-4 bg-primary-bg">
          <div className="self-center w-full pb-4">
            <a href="/" target="_self">
              <div className="flex flex-col border-b-2 shadow-md">
                <img
                  className="w-40 h-auto self-center cursor-pointer"
                  src="https://atquest2.b-cdn.net/AtQuestLogoNew.png"
                  alt="AtQuest-Logo"
                />
              </div>
            </a>
          </div>

          <div className="flex flex-col self-center w-full pl-6 pr-6 sm:pl-0 sm:pr-0">
            <div className="self-center w-full sm:w-sm pt-4 pb-4">
              <div className="flex flex-col text-primary text-h3 text-center">
                Forgot Password
              </div>
            </div>

            <div className="self-center w-full sm:w-sm pt-4 pb-4">
              <div className="flex flex-col">
                <img className="w-full h-auto" src={welcome} alt="welcome" />
              </div>
            </div>

            {!this.state.showVerificationCode ? (
              this.state.successResettingPassword ? (
                <div className="self-center w-full sm:w-sm pt-4 pb-4">
                  <KeyboardEventHandler
                    handleKeys={["enter"]}
                    onKeyEvent={(key, e) => {
                      console.log(`key press detected ${key}`);
                      this.props.history.push(linkLogin);
                    }}
                  >
                    <div className="text-center text-h6 text-primary">
                      Password Reset Successfully!
                    </div>
                  </KeyboardEventHandler>
                  <div className="text-center text-xs">
                    <div className="pt-6 text-button3">
                      <ButtonBox
                        label="Click here to Sign In"
                        className=" text-button3 w-full "
                        onClick={(e) => this.props.history.push(linkLogin)}
                        validation={true}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="self-center w-full sm:w-sm pt-4 pb-4">
                  <KeyboardEventHandler
                    handleKeys={["enter"]}
                    onKeyEvent={(key, e) => {
                      console.log(`key press detected ${key}`);
                      this.validateThenForgotPasswordOTP();
                    }}
                  >
                    <div className="text-center text-h6">
                      <TextBox
                        placeholder="Email"
                        value={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        onBlur={(e) => this.validate("Email")}
                        validation={this.validator.message(
                          "Email",
                          this.state.email,
                          "required|email"
                        )}
                      />
                    </div>
                  </KeyboardEventHandler>
                  <div className="text-center text-xs">
                    <div className="pt-6 text-button3">
                      <ButtonBox
                        label="Reset Password"
                        className=" text-button3 w-full "
                        onClick={(e) => this.validateThenForgotPasswordOTP()}
                        validation={this.validator.allValid()}
                      />
                    </div>
                  </div>
                  <div
                    style={{ flexBasis: 0 }}
                    className="flex-grow cursor-pointer text-center text-h6 pt-4"
                    onClick={(e) => this.props.history.push(linkLogin)}
                  >
                    Back
                  </div>
                </div>
              )
            ) : (
              <div className="self-center w-full sm:w-sm pt-4 pb-4">
                <KeyboardEventHandler
                  handleKeys={["enter"]}
                  onKeyEvent={(key, e) => {
                    console.log(`key press detected ${key}`);
                    this.validateVerificationCodeThenSubmit();
                  }}
                >
                  <div className="text-center text-h6">
                    <TextBox
                      placeholder="Email"
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      onBlur={(e) => this.validate("Email")}
                      validation={this.validator.message(
                        "Email",
                        this.state.email,
                        "required|email"
                      )}
                    />
                  </div>
                  <div className="text-center text-h6">
                    <PasswordText
                      placeholder="New Password"
                      value={this.state.password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      onBlur={(e) => this.validate("Password")}
                      validation={this.validator.message(
                        "Password",
                        this.state.password,
                        "required"
                      )}
                    />
                  </div>

                  <div className="text-h5 text-primary text-center mb-5">
                    Enter the verification code sent to your email id.
                  </div>
                  <div>
                    <AuthenticationCode
                      validation={this.state.verificationcodeerror}
                      onChange={this.handleVerificationCodeChange}
                      className=" text-primary "
                    />
                  </div>
                </KeyboardEventHandler>
                <div className="text-center text-xs">
                  <div className="text-xl">
                    <ButtonBox
                      label="Verify Code"
                      className=" text-button3 w-full "
                      onClick={(e) =>
                        this.validateThentForgotPasswordResetPassword()
                      }
                      validation={
                        this.state.verificationcode &&
                        this.state.verificationcode.length == 6
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-row pt-4">
                  <div
                    style={{ flexBasis: 0 }}
                    className="flex-grow cursor-pointer text-left text-h6"
                    onClick={(e) =>
                      this.setState({
                        ...this.state,
                        showVerificationCode: false,
                        verificationcodeerror: null,
                        verificationcode: null,
                      })
                    }
                  >
                    Back
                  </div>
                  <div
                    style={{ flexBasis: 0 }}
                    className="flex-grow cursor-pointer text-right text-h6 text-error"
                    onClick={(e) => this.props.history.push("")}
                  >
                    Resend Code
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PageForgotPassword;
