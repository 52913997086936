import React, { Component } from "react";
import ReactCodeInput from "react-verification-code-input";
import error from "../../assets/img/error.svg";

class AuthenticationCode extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <ReactCodeInput
            fields={6}
            fieldWidth={45}
            autoFocus={true}
            {...this.props}
          />
        </div>
        <div className="flex flex-row align-middle mt-4 text-xs h-8 text-center text-red-txt justify-center">
          <div className="pr-1">
            {this.props.validation ? (
              <img className="w-4" src={error} alt="error" />
            ) : (
              ""
            )}
          </div>
          <div>{this.props.validation}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default AuthenticationCode;
