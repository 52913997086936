import React from "react";
import ReactDOM from "react-dom";
//import './index.css';
import "./assets/main.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
//import 'primereact/resources/themes/nova-light/theme.css';
import "./assets/custom-theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./assets/custom.css";
import "./assets/custom-table.css";

ReactDOM.render(
  <React.StrictMode>
    <div id="errorDiv12399" className=""></div>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
